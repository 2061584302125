import React from "react"
import Layout from "../components/layout"
import { withPrefix } from "gatsby"
import SEO from "../components/seo"

const IndexPage = ({data}) => ( 

  <Layout>
    <SEO title="Home" />   
      <section id="banner">
        <div className="content">            
          <header>
            <h1>Handleiding Trainers en Coaches</h1>
            <p>Versie 1.1 dd april 2020</p>
          </header>
          <div>            
            <span className="image main"><img src={withPrefix('barons-logo.png')} alt="" /></span>            
          </div>
        </div>
      </section>
  </Layout>
)

export default IndexPage